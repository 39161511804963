<template>
  <Modal :size="'md'" :showModal="showModal">
    <template v-slot:button>
      <div class="flex h-full items-center">
        <button
          @click="showModal = true"
          class="
            rounded-full
            w-12
            h-12
            bg-gray-200
            dark:bg-main4
            flex
            justify-center
            items-center
            leading-none
            hover:bg-gray-300
            transition
            duration-300
            ease-in-out
            dark:text-gray-100
          "
        >
          <PlusIcon class="w-7 h-7" />
        </button>
      </div>
    </template>

    <template v-slot:content>
      <form
        class="space-y-6 w-full divide-y divide-gray-200 dark:divide-gray-700"
        v-if="inputData"
      >
        <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div class="col-span-1">
            <Select
              v-model="form.type"
              :items="inputData.types"
              :errors="errors.type"
              name="display_name"
              placeholder="Tips"
            />
          </div>

          <div class="col-span-1"></div>

          <div class="col-span-1">
            <Input
              name="name"
              :placeholder="
                form.type
                  ? form.type.id === 2
                    ? 'Vārds Uzvārds'
                    : 'Nosaukums'
                  : 'Nosaukums'
              "
              v-model="form.name"
              :errors="errors.name"
            />
          </div>

          <template v-if="form.type && form.type.id === 1">
            <div class="col-span-1">
              <Select
                v-model="form.companyForm"
                :errors="errors.companyForm"
                :items="inputData.company_forms"
                placeholder="Uzņēmuma forma"
              />
            </div>
          </template>
        </div>

        <div class="grid grid-cols-2 gap-6 pt-4">
          <div class="col-span-1">
            <Input
              name="reg_nr"
              :placeholder="
                form.type
                  ? form.type.id === 1
                    ? 'Reģistrācijas nr.'
                    : 'PK'
                  : 'PK'
              "
              v-model="form.reg_nr"
              :errors="errors.reg_nr ? errors.reg_nr : responseErrors.reg_nr"
            />
          </div>

          <template v-if="form.type && form.type.id === 1">
            <div class="col-span-1">
              <Input
                name="vat_nr"
                placeholder="PVN nr."
                v-model="form.vat_nr"
                :errors="errors.vat_nr"
              />
            </div>
          </template>
        </div>

        <div class="grid grid-cols-2 gap-6 pt-4">
          <div class="col-span-1">
            <Input
              name="address"
              :placeholder="
                form.type
                  ? form.type.id === 1
                    ? 'Juridiskā adrese'
                    : 'Adrese'
                  : 'Adrese'
              "
              v-model="form.address"
              :errors="errors.address"
            />
          </div>

          <div class="col-span-1 flex items-center">
            <Checkbox
              text="Piegādes adrese atšķiras"
              v-model="form.differentDeliveryAddress"
            />
          </div>

          <template v-if="form.differentDeliveryAddress">
            <div class="col-span-1">
              <Input
                name="delivery_address"
                placeholder="Piegādes adrese"
                v-model="form.deliveryAddress"
                :errors="errors.deliveryAddress"
              />
            </div>
          </template>
        </div>

        <div class="grid grid-cols-1 md:grid-cols-2 gap-6 pt-4">
          <div class="col-span-1">
            <Autocomplete placeholder="Valsts" v-model="form.country" :errors="errors.country"
                          :items="countries" :showItems="true" :cleanable="false" />
          </div>
        </div>

        <div class="grid grid-cols-2 gap-6 pt-4">
          <div class="col-span-1">
            <Input
              name="phone"
              placeholder="Telefona nr."
              v-model="form.phone"
              :errors="errors.phone"
            />
          </div>
          <div class="col-span-1">
            <Input
              name="email"
              placeholder="E-pasts"
              v-model="form.email"
              :errors="errors.email"
            />
          </div>

            <div class="col-span-2">
                <Input
                    name="email"
                    placeholder="E-pasti rēķiniem"
                    v-model="form.invoice_emails"
                    :errors="errors.invoice_emails"
                />
            </div>
        </div>

        <div class="grid grid-cols-2 gap-6 pt-4">
          <div class="col-span-1">
            <Select
              placeholder="Banka"
              v-model="form.bank"
              :errors="errors.bank"
              :items="inputData.banks"
              :showItems="true"
            />
          </div>

          <div class="col-span-1">
            <Input
              name="iban"
              placeholder="Konta nr."
              v-model="form.iban"
              :errors="errors.iban"
            />
          </div>

            <div class="col-span-1">
                <Input
                    name="iban"
                    type="number"
                    placeholder="Pēcapmaksas dienas"
                    v-model="form.postpay_days"
                    :errors="errors.postpay_days"
                />
            </div>
        </div>

        <template v-if="form.type && form.type.id === 1">
          <div class="grid grid-cols-2 gap-6 pt-4">
            <div class="col-span-1">
              <Input
                name="contactName"
                placeholder="Kontaktpersona"
                v-model="form.contactName"
                :errors="errors.contactName"
              />
            </div>

            <div class="col-span-1">
              <Input
                name="contactPhone"
                placeholder="Kontakta telefona nr."
                v-model="form.contactPhone"
                :errors="errors.contactPhone"
              />
            </div>

            <div class="col-span-1">
              <Input
                name="contactEmail"
                placeholder="Kontakta e-pasts"
                v-model="form.contactEmail"
                :errors="errors.contactEmail"
              />
            </div>
          </div>
        </template>

        <div class="grid grid-cols-2 gap-6 pt-4">
          <div class="col-span-1">
            <Autocomplete
              placeholder="Menedžeris"
              v-model="form.manager"
              :items="inputData.managers"
              :errors="errors.manager"
              :top="true"
              :showItems="true"
            />
          </div>


          <div class="col-span-1 flex items-center">
            <Checkbox text="Eksportam" v-model="form.is_export" />
          </div>
        </div>

        <div class="pt-5">
          <div class="flex justify-end">
            <button
              @click="showModal = false"
              type="button"
              class="
                bg-white
                dark:bg-gray-750
                py-2
                px-4
                border border-gray-300
                dark:border-gray-500
                rounded-md
                shadow-sm
                text-sm
                font-medium
                text-gray-700
                dark:text-gray-300
                hover:bg-gray-50
                dark:hover:bg-gray-770
                focus:outline-none focus:ring-0
                mr-2
              "
            >
              Atcelt
            </button>

            <template v-if="!loading">
              <button
                type="button"
                class="
                  flex
                  justify-center
                  py-2
                  px-4
                  border border-transparent
                  rounded-md
                  shadow-sm
                  text-sm
                  font-medium
                  text-white
                  bg-gradient-to-r
                  from-button2-from
                  to-button2-to
                  hover:from-button2-to hover:to-button2-from
                  focus:outline-none focus:border-none
                  transition
                  duration-500
                  ease-in-out
                "
                @click.prevent="submit"
              >
                Pievienot
              </button>
            </template>
            <template v-else>
              <Loading />
            </template>
          </div>
        </div>
      </form>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/Components/Modal";
import Select from "@/components/Components/Select";
import Input from "@/components/Components/Input";
import Checkbox from "@/components/Components/Checkbox";
import Autocomplete from "@/components/Components/Autocomplete";
import { mapGetters } from "vuex";
import Validation from "@/modules/Validation";
import Loading from "@/components/Components/Loading";
import {
  PlusIcon,
} from "@heroicons/vue/solid";

export default {
  name: "AddCustomer",
  components: {
    Modal,
    Select,
    Input,
    Checkbox,
    Autocomplete,
    Loading,
    PlusIcon,
  },
  data: () => ({
    form: {
      name: "",
      address: "",
      deliveryAddress: "",
      reg_nr: "",
      vat_nr: "",
      type: null,
      bank: null,
      companyForm: null,
      iban: "",
      postpay_days: "",
      phone: "",
      email: "",
      invoice_emails: "",
      contactName: "",
      contactPhone: "",
      contactEmail: "",
      differentDeliveryAddress: false,
      manager: null,
      is_export: false,
      country_id: null,
    },
    showModal: false,
    responseErrors: {},
  }),
  watch: {
    showModal() {
      this.form = {
        name: "",
        address: "",
        deliveryAddress: "",
        reg_nr: "",
        vat_nr: "",
        type: null,
        bank: null,
        companyForm: null,
        iban: "",
        postpay_days: "",
        phone: "",
        email: "",
        invoice_emails: "",
        contactName: "",
        contactPhone: "",
        contactEmail: "",
        differentDeliveryAddress: false,
        manager: null,
        is_export: false,
        country_id: null,
      };
    },
  },
  created() {
    this.$store.dispatch("getCustomerInputData").then((response) => {
      this.form.type = this.inputData.types[0];
    });
    if (!this.countries) {
      this.$store.dispatch('fetchCountries');
    }
  },
  computed: {
    ...mapGetters({
      inputData: "customerInputData",
      countries: "fetchedCountries",
      loading: "loading",
      errors: "errors",
    }),
    setDeliveryAddress() {
      return this.form.differentDeliveryAddress
        ? this.form.deliveryAddress
        : this.form.address;
    },
    setContactName() {
      if (this.form.type) {
        return this.form.type.id === 1 ? this.form.contactName : this.form.name;
      } else {
        return this.form.name;
      }
    },
    setContactPhone() {
      if (this.form.type) {
        return this.form.type.id === 1
          ? this.form.contactPhone
          : this.form.phone;
      } else {
        return this.form.phone;
      }
    },
    setContactEmail() {
      if (this.form.type) {
        return this.form.type.id === 1
          ? this.form.contactEmail
          : this.form.email;
      } else {
        return this.form.email;
      }
    },
    formValidation() {
      return {
        type: {
          rules: ["required"],
        },
        name: {
          rules: ["required"],
        },
        country: {
          rules: ["required"],
        },
        reg_nr: {
          rules: [
            this.form.type && this.form.type.id === 1 ? "required" : null,
          ],
        },
        address: {
          rules: ["required"],
        },
        deliveryAddress: {
          rules: [this.form.differentDeliveryAddress ? "required" : null],
        },
        email: {
          rules: ["email"],
        },
        contactName: {
          rules: [
            this.form.type && this.form.type.id === 1 ? "required" : null,
          ],
        },
      };
    },
  },
  methods: {
    hideAddCustomerForm() {
      if (confirm("Vai esi drošs? Visi ievadītie lauki tiks pazaudēti")) {
        this.$store.dispatch("removeAllFormsForDisplay");
        this.showModal = false;
      }
    },
    clearCompanyForm() {
      this.companyForm = null;
    },
    submit() {
      this.$Progress.start();

      if (Validation(this.formValidation, this.form)) {
        this.$store
          .dispatch("createNewCustomer", {
            data: {
              name: this.form.name,
              address: this.form.address,
              country_id: this.form.country ? this.form.country.id : null,
              company_form_id: this.form.companyForm
                ? this.form.companyForm.id
                : null,
              delivery_address: this.setDeliveryAddress,
              reg_nr: this.form.reg_nr,
              vat_nr: this.form.vat_nr,
              bank_id: this.form.bank ? this.form.bank.id : null,
              iban: this.form.iban,
              postpay_days: this.form.postpay_days,
              phone: this.form.phone,
              email: this.form.email,
              invoice_emails: this.form.invoice_emails,
              customer_contact: {
                name: this.setContactName,
                phone: this.setContactPhone,
                email: this.setContactEmail,
              },
              managing_user_id: this.form.manager ? this.form.manager.id : null,
              is_export: this.form.is_export,
            },
            typeId: this.form.type.id,
          })
          .then(() => {
            this.showModal = false;
          })
          .catch(error => {
            if (error.response.data && error.response.data.errors) {
              this.responseErrors = error.response.data.errors
            }
          });
      } else {
        this.$Progress.fail();
      }
    },
  },
};
</script>